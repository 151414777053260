import { ReactNode } from 'react';
import { Header } from './Header';

interface LayoutProps {
  children: ReactNode;
  showHeader?: boolean;
  className?: string;
  variant?: 'primary' | 'light';
}

export function Layout({ 
  children, 
  showHeader = true, 
  className = '',
  variant = 'light'
}: LayoutProps) {
  return (
    <div className={`
      min-h-screen 
      ${variant === 'primary' ? 'bg-primary-500' : 'bg-primary-50'}
      flex 
      flex-col
    `}>
      {showHeader && <Header />}
      <main 
        className={`
          flex-1
          px-3
          xs:px-4
          sm:px-6
          pb-20
          sm:pb-24
          md:pb-32
          max-w-2xl 
          w-full
          mx-auto 
          ${showHeader ? 'pt-16 sm:pt-20' : 'pt-4 sm:pt-6'}
          ${className}
        `.trim()}
      >
        {children}
      </main>
    </div>
  );
}