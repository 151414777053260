import { OrderData } from '../types/order';
import { QuoteLineItem } from '../../types/stripe';

// Pricing configuration and calculations
export const pricing = {
  basePrice: 24.90,
  certificatePrice: 3.50,
  coloringBookPrice: 3.50,
  discounts: {
    2: 0.15, // 15% off for 2 children
    3: 0.25, // 25% off for 3 children
    4: 0.30, // 30% off for 4 children
    5: 0.35, // 35% off for 5 children
  },
} as const;

interface PriceCalculation {
  total: number;
  savings: number;
  originalTotal: number;
  certificatesTotal: number;
  coloringBooksTotal: number;
  grandTotal: number;
}

export function calculatePrice(
  childCount: number,
  certificateCount: number = 0,
  coloringBookCount: number = 0
): PriceCalculation {
  // Calculate video message price with discounts
  const originalTotal = pricing.basePrice * childCount;
  const discount = pricing.discounts[childCount as keyof typeof pricing.discounts] || 0;
  const discountedTotal = originalTotal * (1 - discount);
  const savings = originalTotal - discountedTotal;

  // Calculate add-ons total (no discount applied)
  const certificatesTotal = certificateCount * pricing.certificatePrice;
  const coloringBooksTotal = coloringBookCount * pricing.coloringBookPrice;

  // Calculate grand total
  const grandTotal = discountedTotal + certificatesTotal + coloringBooksTotal;

  return {
    total: Number(discountedTotal.toFixed(2)),
    savings: Number(savings.toFixed(2)),
    originalTotal: Number(originalTotal.toFixed(2)),
    certificatesTotal: Number(certificatesTotal.toFixed(2)),
    coloringBooksTotal: Number(coloringBooksTotal.toFixed(2)),
    grandTotal: Number(grandTotal.toFixed(2)),
  };
}

// Add createLineItems function
export function createLineItems(orderData: OrderData) {
  const items: QuoteLineItem[] = [];

  // Add video messages
  if (orderData.childCount > 0) {
    items.push({
      price_data: {
        currency: 'eur',
        unit_amount: 2490, // 24.90 EUR in cents
        product: 'Božičkovo Video Sporočilo'
      },
      quantity: orderData.childCount,
    });
  }

  // Add certificates
  if (orderData.certificateCount > 0) {
    items.push({
      price_data: {
        currency: 'eur',
        unit_amount: 350, // 3.50 EUR in cents
        product: 'Certifikat pridnosti'
      },
      quantity: orderData.certificateCount,
    });
  }

  // Add coloring books
  if (orderData.coloringBookCount > 0) {
    items.push({
      price_data: {
        currency: 'eur',
        unit_amount: 350, // 3.50 EUR in cents
        product: 'Božičkova pobarvanka'
      },
      quantity: orderData.coloringBookCount,
    });
  }

  return items;
}