import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import type { StripeElementsOptions } from '@stripe/stripe-js';
import { stripeConfig } from '../../config/stripe';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

interface StripeWrapperProps {
  children: React.ReactNode;
  clientSecret?: string;
}

export function PaymentWrapper({ children, clientSecret }: StripeWrapperProps) {
  const options: StripeElementsOptions = {
    clientSecret,
    ...stripeConfig,
    appearance: {
      theme: 'stripe',
      ...stripeConfig.appearance
    }
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  );
}

export function CompletionWrapper({ children }: StripeWrapperProps) {
  return (
    <Elements stripe={stripePromise}>
      {children}
    </Elements>
  );
} 