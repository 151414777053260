import React, { useState } from 'react';
import { Text } from '../ui/Text';
import { Input } from '../ui/Input';
import { Button } from '../ui/Button';

interface ParentInfo {
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  postalCode: string;
  city: string;
  phone?: string;
}

interface ParentInfoFormProps {
  onSubmit: (info: ParentInfo) => void;
  initialData?: ParentInfo | null;
}

export function ParentInfoForm({ onSubmit, initialData }: ParentInfoFormProps) {
  const [formData, setFormData] = useState<ParentInfo>(() => {
    if (initialData) {
      return initialData;
    }
    return {
      firstName: '',
      lastName: '',
      email: '',
      address: '',
      postalCode: '',
      city: '',
      phone: '',
    };
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const handleChange = (field: keyof ParentInfo) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormData(prev => ({
      ...prev,
      [field]: e.target.value
    }));
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="space-y-6">
        <Text variant="large" className="font-semibold">
          Podatki za dostavo
        </Text>

        <div className="space-y-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4">
            <div>
              <Text variant="small" className="font-medium mb-2">
                Ime
              </Text>
              <Input
                required
                type="text"
                value={formData.firstName}
                onChange={handleChange('firstName')}
                placeholder="Vaše ime"
              />
            </div>
            <div>
              <Text variant="small" className="font-medium mb-2">
                Priimek
              </Text>
              <Input
                required
                type="text"
                value={formData.lastName}
                onChange={handleChange('lastName')}
                placeholder="Vaš priimek"
              />
            </div>
          </div>

          <div>
            <Text variant="small" className="font-medium mb-2">
              E-pošta
            </Text>
            <Input
              required
              type="email"
              value={formData.email}
              onChange={handleChange('email')}
              placeholder="email@primer.si"
            />
          </div>

          <div>
            <Text variant="small" className="font-medium mb-2">
              Naslov
            </Text>
            <Input
              required
              type="text"
              value={formData.address}
              onChange={handleChange('address')}
              placeholder="Ulica in hišna številka"
            />
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4">
            <div>
              <Text variant="small" className="font-medium mb-2">
                Poštna številka
              </Text>
              <Input
                required
                type="text"
                value={formData.postalCode}
                onChange={handleChange('postalCode')}
                placeholder="1000"
              />
            </div>
            <div>
              <Text variant="small" className="font-medium mb-2">
                Kraj
              </Text>
              <Input
                required
                type="text"
                value={formData.city}
                onChange={handleChange('city')}
                placeholder="Ljubljana"
              />
            </div>
          </div>

          <div>
            <Text variant="small" className="font-medium mb-2">
              Telefon (neobvezno)
            </Text>
            <Input
              type="tel"
              value={formData.phone}
              onChange={handleChange('phone')}
              placeholder="031 123 456"
            />
          </div>
        </div>
      </div>

      <div className="fixed bottom-4 sm:bottom-6 md:bottom-8 left-0 right-0 px-3 xs:px-4 sm:px-6 max-w-2xl mx-auto">
        <Button
          type="submit"
          variant="accent"
          size="lg"
          fullWidth
        >
          Nadaljuj na plačilo
        </Button>
      </div>
    </form>
  );
}