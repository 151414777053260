import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Layout } from '../components/Layout';
import { Card } from '../components/ui/Card';
import { Text } from '../components/ui/Text';
import { Button } from '../components/ui/Button';
import { Building2, FileText, Calendar, Loader2, AlertCircle } from 'lucide-react';
import { motion } from 'framer-motion';
import { firebaseService } from '../services/firebase';

interface QuoteDetails {
  id: string;
  status: 'draft' | 'open' | 'accepted' | 'canceled' | 'paid';
  amount_total: number;
  currency: string;
  expires_at: number;
  hosted_invoice_url?: string;
  invoice_pdf?: string;
  customer_email: string;
  bank_details?: {
    iban: string;
    reference: string;
    amount: number;
    currency: string;
  };
}

export function QuoteStatusPage() {
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState<'loading' | 'success' | 'error'>('loading');
  const [quoteDetails, setQuoteDetails] = useState<QuoteDetails | null>(null);
  const [error, setError] = useState<string | null>(null);

  const fetchPdfUrl = async (orderId: string) => {
    try {
      const orderDoc = await firebaseService.getOrder(orderId);
      return orderDoc?.payment?.quotePdfUrl;
    } catch (error) {
      console.error('Error fetching PDF URL:', error);
      return null;
    }
  };

  useEffect(() => {
    const checkQuoteStatus = async () => {
      try {
        const quoteId = searchParams.get('quote_id');
        const orderId = searchParams.get('order_id');

        if (!quoteId || !orderId) {
          throw new Error('Missing quote or order ID');
        }

        const response = await fetch(`/api/quote-status/${quoteId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch quote status');
        }

        const data = await response.json();
        setQuoteDetails(data);
        setStatus('success');

        if (orderId) {
          fetchPdfUrl(orderId).then(pdfUrl => {
            if (pdfUrl) {
              setQuoteDetails(prev => prev ? { ...prev, pdf: pdfUrl } : null);
            }
          });
        }

      } catch (error) {
        console.error('Error checking quote status:', error);
        setError(error instanceof Error ? error.message : 'An error occurred');
        setStatus('error');
      }
    };

    checkQuoteStatus();
  }, [searchParams]);

  return (
    <Layout variant="light">
      <div className="max-w-lg mx-auto p-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          {status === 'loading' ? (
            <Card variant="white" className="p-6">
              <div className="text-center">
                <Loader2 className="w-12 h-12 text-primary-500 animate-spin mx-auto mb-4" />
                <Text variant="large" className="font-semibold mb-2">
                  Preverjanje predračuna...
                </Text>
                <Text variant="base" className="text-neutral-600">
                  Prosimo počakajte, preverjamo status vašega predračuna.
                </Text>
              </div>
            </Card>
          ) : status === 'error' ? (
            <Card variant="white" className="p-6">
              <div className="text-center">
                <AlertCircle className="w-12 h-12 text-red-500 mx-auto mb-4" />
                <Text variant="large" className="font-semibold mb-2">
                  Napaka pri preverjanju
                </Text>
                {error && (
                  <div className="bg-red-50 text-red-700 p-4 rounded-lg mb-4">
                    {error}
                  </div>
                )}
              </div>
            </Card>
          ) : quoteDetails ? (
            <Card variant="white" className="p-6">
              <div className="space-y-6">
                <div className="text-center">
                  <Building2 className="w-12 h-12 text-primary-500 mx-auto mb-4" />
                  <Text variant="large" className="font-semibold">
                    Predračun je pripravljen
                  </Text>
                  <Text variant="base" className="text-neutral-600 mt-2">
                    Predračun smo poslali na {quoteDetails.customer_email}
                  </Text>
                </div>

                <div className="space-y-4">
                  {/* Amount */}
                  <div className="p-4 bg-primary-50 rounded-xl">
                    <Text variant="large" className="font-semibold text-primary-600">
                      {(quoteDetails.amount_total / 100).toFixed(2)} €
                    </Text>
                    <Text variant="small" className="text-primary-600">
                      Skupni znesek za plačilo
                    </Text>
                  </div>

                  {/* Expiration */}
                  <div className="flex items-center gap-3 p-3 rounded-lg bg-neutral-50">
                    <Calendar className="w-5 h-5 text-neutral-500" />
                    <div>
                      <Text variant="small" className="text-neutral-600">
                        Rok plačila
                      </Text>
                      <Text variant="base" className="font-medium">
                        {new Date(quoteDetails.expires_at * 1000).toLocaleDateString('sl')}
                      </Text>
                    </div>
                  </div>

                  {/* Actions */}
                  <div className="space-y-3 pt-4">
                    {quoteDetails.invoice_pdf && (
                      <Button
                        variant="secondary"
                        size="lg"
                        fullWidth
                        onClick={() => window.open(quoteDetails.invoice_pdf, '_blank')}
                      >
                        <FileText className="w-4 h-4 mr-2" />
                        Prenesi predračun
                      </Button>
                    )}
                    
                    <Button
                      variant="secondary"
                      size="lg"
                      fullWidth
                      onClick={() => window.location.reload()}
                    >
                      Preveri status ponovno
                    </Button>
                  </div>

                  {/* Bank Transfer Instructions */}
                  <div className="border border-primary-100 rounded-xl p-4 space-y-3">
                    <Text variant="base" className="font-semibold">
                      Navodila za plačilo
                    </Text>
                    <div className="space-y-2">
                      <div>
                        <Text variant="small" className="text-neutral-500">
                          Namen plačila
                        </Text>
                        <Text variant="base" className="font-medium">
                          Božičkovo video sporočilo - {quoteDetails.id}
                        </Text>
                      </div>
                      <div>
                        <Text variant="small" className="text-neutral-500">
                          IBAN
                        </Text>
                        <Text variant="base" className="font-medium">
                          SI56 1234 5678 9012 345
                        </Text>
                      </div>
                      <div>
                        <Text variant="small" className="text-neutral-500">
                          Referenca
                        </Text>
                        <Text variant="base" className="font-medium">
                          SI00 {quoteDetails.id.replace(/\D/g, '')}
                        </Text>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          ) : null}
        </motion.div>
      </div>
    </Layout>
  );
} 