import type { Appearance } from '@stripe/stripe-js';

const appearance: Appearance = {
  theme: 'stripe',
  variables: {
    colorPrimary: '#0055DE',
    colorBackground: '#ffffff',
    colorText: '#30313D',
    colorDanger: '#df1b41',
    fontFamily: 'system-ui, -apple-system, "Segoe UI", Roboto, sans-serif',
    spacingUnit: '4px',
    borderRadius: '4px',
  },
  rules: {
    '.Tab': {
      border: '1px solid #E6E6E6',
      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03)',
    },
    '.Tab:hover': {
      color: '#0055DE',
    },
    '.Tab--selected': {
      borderColor: '#0055DE',
      color: '#0055DE',
    },
    '.Input': {
      padding: '12px',
      borderRadius: '4px',
      border: '1px solid #E6E6E6',
    },
    '.Input:focus': {
      borderColor: '#0055DE',
      boxShadow: '0 1px 3px 0 #e6ebf1',
    },
  },
};

export const stripeConfig = {
  appearance,
  loader: 'auto' as const,
}; 