import { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { X, ChevronLeft } from 'lucide-react';
import { Text } from '../ui/Text';
import { ChildFormStep } from './ChildFormStep';
import { CertificateUpsell } from './CertificateUpsell';
import { ColoringBookUpsell } from './ColoringBookUpsell';
import { OrderSummary } from './OrderSummary';
import { ChildData } from '../../types/child';

interface ChildFormModalProps {
  isOpen: boolean;
  onClose: (data?: ChildData) => void;
  childNumber: number;
  totalChildren: number;
  initialData?: ChildData;
}

export function ChildFormModal({ 
  isOpen, 
  onClose,
  childNumber,
  totalChildren,
  initialData 
}: ChildFormModalProps) {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState<ChildData>({
    name: '',
    age: '',
    giftWish: '',
    interests: '',
    achievements: [],
    wantsCertificate: false,
    wantsColoringBook: false
  });

  // Reset form when modal opens or initialData changes
  useEffect(() => {
    if (isOpen) {
      setStep(1);
      setFormData(initialData || {
        name: '',
        age: '',
        giftWish: '',
        interests: '',
        achievements: [],
        wantsCertificate: false,
        wantsColoringBook: false
      });
    }
  }, [isOpen, initialData]);

  const totalSteps = 8; // Added summary step

  const handleNext = () => {
    if (step < totalSteps) {
      setStep(step + 1);
    }
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleComplete = () => {
    onClose(formData);
  };

  const renderContent = () => {
    if (step === 6) {
      return (
        <CertificateUpsell
          childName={formData.name}
          onSelect={(wantsCertificate) => {
            setFormData((prev: ChildData) => ({ ...prev, wantsCertificate }));
            handleNext();
          }}
          selected={formData.wantsCertificate}
        />
      );
    }

    if (step === 7) {
      return (
        <ColoringBookUpsell
          childName={formData.name}
          onSelect={(wantsColoringBook) => {
            setFormData((prev: ChildData) => ({ ...prev, wantsColoringBook }));
            handleNext();
          }}
          selected={formData.wantsColoringBook}
        />
      );
    }

    if (step === 8) {
      return (
        <OrderSummary
          childName={formData.name}
          age={formData.age}
          wantsCertificate={formData.wantsCertificate || false}
          wantsColoringBook={formData.wantsColoringBook || false}
          onComplete={handleComplete}
        />
      );
    }

    return (
      <ChildFormStep
        step={step}
        formData={formData}
        onChange={(key, value) => 
          setFormData((prev: ChildData) => ({ ...prev, [key]: value }))
        }
        onNext={handleNext}
      />
    );
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="
            fixed inset-0 z-50 
            bg-black/50 
            flex items-end sm:items-center justify-center 
            overflow-hidden
            min-h-[-webkit-fill-available]  /* Fix for iOS height */
          "
        >
          <motion.div
            initial={{ y: '100%' }}
            animate={{ y: 0 }}
            exit={{ y: '100%' }}
            transition={{ type: 'spring', damping: 25, stiffness: 200 }}
            className="
              w-full 
              h-[85vh] 
              max-h-[calc(100vh-env(safe-area-inset-top)-env(safe-area-inset-bottom))]
              sm:h-auto 
              sm:max-h-[85vh]
              sm:w-[480px] 
              bg-white 
              rounded-t-2xl sm:rounded-2xl 
              overflow-hidden
              flex flex-col
              relative
            "
          >
            {/* Header - Make it sticky */}
            <div className="
              sticky top-0 z-10
              flex items-center justify-center 
              px-4 h-16 
              border-b border-neutral-100
              bg-white
            ">
              {/* Back button (left) - only show after step 1 */}
              {step > 1 && (
                <button
                  onClick={handleBack}
                  className="absolute left-3 p-2 rounded-full hover:bg-neutral-50 active:bg-neutral-100"
                >
                  <ChevronLeft className="w-5 h-5 text-neutral-600" />
                </button>
              )}
              
              {/* Title (center) */}
              <div className="flex flex-col items-center">
                <Text variant="base" className="font-medium">
                  {totalChildren > 1 ? `Otrok ${childNumber}` : 'Podatki o otroku'}
                </Text>
                <Text variant="small" className="text-neutral-500">
                  Korak {step} od {totalSteps}
                </Text>
              </div>

              {/* Close button (right) */}
              <button
                onClick={handleClose}
                className="absolute right-3 p-2 rounded-full hover:bg-neutral-50 active:bg-neutral-100"
                aria-label="Zapri"
              >
                <X className="w-5 h-5 text-neutral-600" />
              </button>
            </div>

            {/* Progress Bar - Make it sticky */}
            <div className="sticky top-16 z-10 h-1 bg-neutral-100">
              <div 
                className="h-full bg-primary-500 transition-all duration-300"
                style={{ width: `${(step / totalSteps) * 100}%` }}
              />
            </div>

            {/* Content - Allow scrolling */}
            <div className="
              p-6 
              flex-1 
              overflow-y-auto
              overscroll-contain
              -webkit-overflow-scrolling: touch
            ">
              {renderContent()}
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}