import React from 'react';
import { Header } from './Header';
import { Text } from './ui/Text';

interface PageHeaderProps {
  step: number;
  totalSteps: number;
  title: string;
  onBack: () => void;
}

export function PageHeader({ 
  step, 
  totalSteps, 
  title, 
  onBack 
}: PageHeaderProps) {
  return (
    <>
      <Header
        showBack
        onBack={onBack}
        title={title}
        subtitle={`Korak ${step} od ${totalSteps}`}
        progress={{
          current: step,
          total: totalSteps
        }}
      />
      {/* Spacer for fixed header */}
      <div className="h-14" />
    </>
  );
}