import { Card } from '../ui/Card';
import { Text } from '../ui/Text';
import { calculatePrice } from '../../utils/pricing';
import { OrderData } from '../../types/order';

export interface OrderSummaryProps {
  orderData: OrderData;
}

export function OrderSummary({ orderData }: OrderSummaryProps) {
  const { 
    total, 
    savings, 
    certificatesTotal,
    coloringBooksTotal,
    grandTotal 
  } = calculatePrice(
    orderData.childCount,
    orderData.certificateCount,
    orderData.coloringBookCount
  );

  return (
    <Card variant="white" className="p-4 sm:p-6">
      <div className="space-y-6">
        <Text variant="large" className="font-semibold">
          Pregled naročila
        </Text>

        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <Text variant="base" className="text-neutral-600">
              Video sporočilo ({orderData.childCount}×):
            </Text>
            <Text variant="base" className="font-semibold">
              {total} €
            </Text>
          </div>

          {orderData.certificateCount > 0 && (
            <div className="flex items-center justify-between">
              <Text variant="base" className="text-neutral-600">
                Certifikat ({orderData.certificateCount}×):
              </Text>
              <Text variant="base" className="font-semibold">
                {certificatesTotal} €
              </Text>
            </div>
          )}

          {orderData.coloringBookCount > 0 && (
            <div className="flex items-center justify-between">
              <Text variant="base" className="text-neutral-600">
                Pobarvanka ({orderData.coloringBookCount}×):
              </Text>
              <Text variant="base" className="font-semibold">
                {coloringBooksTotal} €
              </Text>
            </div>
          )}

          {savings > 0 && (
            <div className="flex items-center justify-between text-accent-red-500">
              <Text variant="base">
                Prihranek:
              </Text>
              <Text variant="base" className="font-semibold">
                -{savings} €
              </Text>
            </div>
          )}

          <div className="pt-4 border-t border-neutral-100">
            <div className="flex items-center justify-between">
              <Text variant="base" className="font-semibold">
                Skupaj za plačilo:
              </Text>
              <Text variant="large" className="font-semibold text-primary-500">
                {grandTotal} €
              </Text>
            </div>
            <Text variant="small" className="text-neutral-500 mt-1">
              DDV je vključen v ceno
            </Text>
          </div>
        </div>
      </div>
    </Card>
  );
}