import React from 'react';
import { Plus, Minus } from 'lucide-react';

interface ChildCounterProps {
  count: number;
  onChange: (count: number) => void;
}

export function ChildCounter({ count, onChange }: ChildCounterProps) {
  return (
    <div className="flex items-center justify-center gap-6">
      <button 
        onClick={() => count > 1 && onChange(count - 1)}
        className="w-12 h-12 flex items-center justify-center rounded-full bg-primary-100 text-primary-500 disabled:opacity-50"
        disabled={count <= 1}
      >
        <Minus className="w-5 h-5" />
      </button>
      
      <div className="w-20 h-20 rounded-full bg-white shadow-lg flex items-center justify-center">
        <span className="text-3xl font-bold text-primary-500">{count}</span>
      </div>

      <button 
        onClick={() => count < 5 && onChange(count + 1)}
        className="w-12 h-12 flex items-center justify-center rounded-full bg-primary-100 text-primary-500 disabled:opacity-50"
        disabled={count >= 5}
      >
        <Plus className="w-5 h-5" />
      </button>
    </div>
  );
}