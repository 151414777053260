import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from '../components/PageHeader';
import { ParentInfoForm } from '../components/Payment/ParentInfoForm';
import { PaymentMethodSelection } from '../components/Payment/PaymentMethodSelection';
import { OrderSummary } from '../components/Payment/OrderSummary';
import { StripePayment } from '../components/Payment/StripePayment';
import { PaymentWrapper } from '../components/Payment/StripeWrappers';
import { OrderData } from '../types/order';
import { firebaseService } from '../services/firebase';
import { Building, CheckCircle } from 'lucide-react';
import { Button } from '../components/ui/Button';
import { Loader2 } from 'lucide-react';
import { Text } from '../components/ui/Text';
import { Layout } from '../components/Layout';
import { Card } from '../components/ui/Card';
import { motion } from 'framer-motion';
import { calculatePrice, createLineItems } from '../utils/pricing';
import { LetterStatus, PaymentStatus, FulfillmentStatus } from '../types/firebase';
import { Timestamp } from 'firebase/firestore';

interface ParentInfo {
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  postalCode: string;
  city: string;
  phone?: string;
}

type PaymentStep = 'info' | 'method' | 'processing' | 'quote_processing' | 'quote_success';

interface ChildData {
  videoMessageUrl?: string;
  santaLetter?: {
    status: LetterStatus;
    content?: string;
    generatedAt?: Timestamp;
    approvedAt?: Timestamp;
  };
}

// Add interface for customer info
interface CustomerInfo {
  email: string;
  firstName: string;
  lastName: string;
  address?: {
    street: string;
    postalCode: string;
    city: string;
    country: string;
  };
}

export function PaymentPage() {
  const navigate = useNavigate();
  const [step, setStep] = useState<PaymentStep>('info');
  const [parentInfo, setParentInfo] = useState<ParentInfo | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState<string | null>(null);

  // Get order data from localStorage
  const orderData: OrderData = JSON.parse(localStorage.getItem('orderData') || '{}');

  const calculateTotalAmount = (data: OrderData) => {
    const { grandTotal } = calculatePrice(
      data.childCount,
      data.certificateCount,
      data.coloringBookCount
    );
    return grandTotal;
  };

  const handleParentInfoSubmit = async (info: ParentInfo) => {
    setParentInfo(info);
    setStep('method');
  };

  // Handle card payment
  const handleCardPayment = async (
    order: { id: string },
    customer: CustomerInfo,
    amount: number
  ) => {
    try {
      console.log(' Creating payment intent:', { orderId: order.id, amount });

      const response = await fetch('/api/create-payment-intent', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          orderId: order.id,
          amount,
          customer: {
            email: customer.email,
            firstName: customer.firstName,
            lastName: customer.lastName,
            address: {
              line1: customer.address?.street || parentInfo!.address,
              postal_code: customer.address?.postalCode || parentInfo!.postalCode,
              city: parentInfo!.city,
              country: 'SI'
            }
          }
        })
      });

      if (!response.ok) {
        const text = await response.text();
        console.error('Response status:', response.status);
        console.error('Response text:', text);
        console.error('Request URL:', response.url);
        try {
          const errorData = JSON.parse(text);
          throw new Error(errorData.error || 'Failed to create payment intent');
        } catch {
          throw new Error(`Server error: ${text || response.statusText}`);
        }
      }

      const data = await response.json();
      console.log('✅ Payment intent created:', { hasClientSecret: !!data.clientSecret });

      if (!data.clientSecret) {
        throw new Error('No client secret received');
      }

      setClientSecret(data.clientSecret);
      setStep('processing');
    } catch (error) {
      console.error('❌ Card payment setup error:', error);
      throw error;
    }
  };

  const handlePaymentMethodSelect = async (method: 'card' | 'bank_transfer') => {
    try {
      // Log initial state
      console.log('🔄 Starting payment method selection:', { 
        method,
        parentInfo: parentInfo ? {
          email: parentInfo.email,
          firstName: parentInfo.firstName,
          lastName: parentInfo.lastName
        } : null,
        orderData 
      });

      setIsLoading(true);
      setError(null);

      // Create customer
      const customer = await firebaseService.createCustomer({
        firstName: parentInfo!.firstName,
        lastName: parentInfo!.lastName,
        email: parentInfo!.email,
        phone: parentInfo!.phone,
        address: {
          street: parentInfo!.address,
          city: parentInfo!.city,
          postalCode: parentInfo!.postalCode,
          country: 'SI'
        }
      });

      // Log customer creation
      console.log('✅ Customer created:', customer);

      const amount = calculateTotalAmount(orderData);
      
      // Log amount calculation
      console.log('💰 Calculated amount:', { 
        amount,
        orderData,
        pricing: {
          basePrice: orderData.childCount * 24.90,
          certificatesPrice: orderData.certificateCount * 3.50,
          coloringBooksPrice: orderData.coloringBookCount * 3.50
        }
      });

      // Create order
      const order = await firebaseService.createOrder({
        customerId: customer.id,
        customer: {
          firstName: customer.firstName,
          lastName: customer.lastName,
          email: customer.email
        },
        status: 'pending',
        orderDetails: {
          childCount: orderData.childCount,
          pricing: {
            basePrice: orderData.childCount * 24.90,
            certificatesPrice: orderData.certificateCount * 3.50,
            coloringBooksPrice: orderData.coloringBookCount * 3.50,
            bundleDiscount: 0,
            totalAmount: amount,
            currency: 'eur',
          },
          children: Object.entries(orderData.childrenData || {}).reduce<Record<string, ChildData>>((acc) => {
            const childId = window.crypto.randomUUID();
            acc[childId] = {
              santaLetter: {
                status: 'pending' as LetterStatus
              }
            };
            return acc;
          }, {}),
          addOns: {
            certificates: {
              quantity: orderData.certificateCount || 0,
              childIds: []
            },
            coloringBooks: {
              quantity: orderData.coloringBookCount || 0,
              childIds: []
            }
          }
        },
        payment: {
          method: method,
          status: (method === 'bank_transfer' ? 'pending' : 'processing') as PaymentStatus
        },
        fulfillment: {
          status: 'pending' as FulfillmentStatus,
          stages: {
            letterGenerated: false,
            letterApproved: false,
            videoRecorded: false,
            packagePrepared: false,
            shipped: false
          }
        }
      });

      // Log order creation
      console.log('📦 Order created:', { 
        orderId: order.id,
        method,
        amount 
      });

      if (method === 'bank_transfer') {
        console.log('🏦 Starting bank transfer flow');
        setStep('quote_processing');
        
        const response = await fetch('/api/quotes', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            orderData: {
              id: order.id,
              customer: {
                email: customer.email,
                firstName: customer.firstName,
                lastName: customer.lastName,
                address: {
                  line1: parentInfo!.address,
                  city: parentInfo!.city,
                  postal_code: parentInfo!.postalCode,
                  country: 'SI'
                }
              },
              orderDetails: {
                childCount: orderData.childCount,
                items: createLineItems(orderData),
                pricing: {
                  totalAmount: amount
                }
              },
              payment: {
                method: 'bank_transfer'
              }
            }
          })
        });

        // Log quote response
        console.log('📝 Quote response:', { 
          status: response.status,
          ok: response.ok 
        });

        if (!response.ok) {
          const errorData = await response.json();
          console.error('❌ Quote error:', errorData);
          throw new Error(errorData.error || 'Failed to create quote');
        }

        const { quoteUrl } = await response.json();
        console.log('✅ Quote created:', { quoteUrl });
        
        if (!quoteUrl) {
          throw new Error('No quote URL received');
        }

        setStep('quote_success');
        
        // Show success message before redirect
        setTimeout(() => {
          window.location.href = quoteUrl;
        }, 2000);
      } else {
        console.log('💳 Starting card payment flow');
        // Card payment flow
        await handleCardPayment(order, customer, amount);
      }
    } catch (error) {
      // Log error with context
      console.error('❌ Payment method selection error:', {
        error: error instanceof Error ? error.message : String(error),
        step,
        isLoading,
        method
      });
      setError(error instanceof Error ? error.message : 'An error occurred');
      setStep('method');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log('🔄 PaymentPage mounted');
    const storedData = localStorage.getItem('orderData');
    console.log('📦 Stored order data:', storedData);
  }, []);

  console.log('🎨 Rendering PaymentPage:', { step, isLoading, error });

  useEffect(() => {
    console.log('🔄 Payment state:', { 
      step, 
      hasClientSecret: !!clientSecret,
      isLoading,
      error 
    });
  }, [step, clientSecret, isLoading, error]);

  return (
    <Layout variant="light" showHeader={false}>
      <PageHeader
        step={2}
        totalSteps={3}
        title="Plačilo"
        onBack={() => {
          if (step === 'method') setStep('info');
          else navigate(-1);
        }}
      />

      <motion.main 
        className="space-y-4 xs:space-y-6 sm:space-y-8"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        {/* Order Summary */}
        <motion.section
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
        >
          <OrderSummary orderData={orderData} />
        </motion.section>

        {isLoading ? (
          <Card variant="white" className="p-4 sm:p-6">
            <div className="py-8 text-center">
              <Loader2 className="w-8 h-8 text-primary-500 animate-spin mx-auto mb-4" />
              <Text variant="base" className="text-neutral-600">
                Pripravljamo plačilo...
              </Text>
            </div>
          </Card>
        ) : error ? (
          <Card variant="white" className="p-4 sm:p-6">
            <div className="p-4 rounded-lg bg-red-50 text-red-700">
              {error}
            </div>
          </Card>
        ) : (
          // Payment Steps
          <motion.section
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="space-y-4"
          >
            {step === 'info' ? (
              <Card variant="white" className="p-4 sm:p-6">
                <ParentInfoForm
                  onSubmit={handleParentInfoSubmit}
                  initialData={parentInfo}
                />
              </Card>
            ) : step === 'method' ? (
              <Card variant="white" className="p-4 sm:p-6">
                <PaymentMethodSelection
                  onSelect={handlePaymentMethodSelect}
                  parentInfo={parentInfo!}
                  orderData={null}
                />
              </Card>
            ) : step === 'quote_processing' ? (
              <Card variant="white" className="p-4 sm:p-6">
                <div className="text-center space-y-4">
                  <div className="w-16 h-16 bg-primary-100 rounded-full flex items-center justify-center mx-auto mb-4">
                    <Building className="w-8 h-8 text-primary-500" />
                  </div>
                  <Text variant="large" className="font-semibold">
                    Predračun je pripravljen
                  </Text>
                  <Text variant="base" className="text-neutral-600">
                    Na email {parentInfo?.email} smo vam poslali predračun s podatki za plačilo.
                  </Text>
                  <div className="pt-4">
                    <Button
                      variant="secondary"
                      size="lg"
                      fullWidth
                      onClick={() => navigate('/')}
                    >
                      Nazaj na domačo stran
                    </Button>
                  </div>
                </div>
              </Card>
            ) : step === 'quote_success' ? (
              <Card variant="white" className="p-4 sm:p-6">
                <div className="text-center space-y-4">
                  <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
                    <CheckCircle className="w-8 h-8 text-green-500" />
                  </div>
                  <Text variant="large" className="font-semibold">
                    Predračun je pripravljen
                  </Text>
                  <Text variant="base" className="text-neutral-600">
                    Na email {parentInfo?.email} smo vam poslali predračun s podatki za plačilo. Preusmerjamo vas na predračun...
                  </Text>
                  <div className="pt-4">
                    <div className="animate-spin w-6 h-6 border-2 border-primary-500 border-t-transparent rounded-full mx-auto"></div>
                  </div>
                </div>
              </Card>
            ) : clientSecret ? (
              <Card variant="white" className="p-4 sm:p-6">
                <PaymentWrapper clientSecret={clientSecret}>
                  <StripePayment
                    amount={calculateTotalAmount(orderData)}
                    onError={setError}
                    onCancel={() => setStep('method')}
                    customerInfo={{
                      name: `${parentInfo!.firstName} ${parentInfo!.lastName}`,
                      email: parentInfo!.email,
                      phone: parentInfo!.phone,
                      address: {
                        line1: parentInfo!.address,
                        postal_code: parentInfo!.postalCode,
                        city: parentInfo!.city,
                        country: 'SI'
                      }
                    }}
                  />
                </PaymentWrapper>
              </Card>
            ) : null}
          </motion.section>
        )}
      </motion.main>
    </Layout>
  );
}