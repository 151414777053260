import { motion } from 'framer-motion';
import { CheckCircle, Gift, Star } from 'lucide-react';
import { Text } from '../ui/Text';
import { Button } from '../ui/Button';

interface OrderSummaryProps {
  childName: string;
  age: string;
  wantsCertificate: boolean;
  wantsColoringBook: boolean;
  onComplete: () => void;
}

export function OrderSummary({
  childName,
  age,
  wantsCertificate,
  wantsColoringBook,
  onComplete
}: OrderSummaryProps) {
  return (
    <motion.div 
      className="space-y-6"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="text-center">
        <div className="relative inline-block mb-4">
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ 
              type: "spring",
              duration: 0.8,
              bounce: 0.5
            }}
            className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-primary-100 text-primary-500"
          >
            <CheckCircle className="w-8 h-8" />
          </motion.div>
        </div>
        
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <Text variant="large" className="font-semibold mb-2">
            Super! Podatki so shranjeni
          </Text>
          <Text variant="base" className="text-neutral-600">
            Pregled naročila za {childName} ({age} let)
          </Text>
        </motion.div>
      </div>

      <motion.div 
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.3, duration: 0.5 }}
        className="space-y-4"
      >
        {/* Order Items */}
        <div className="bg-neutral-50 rounded-xl p-4 space-y-3">
          <div className="flex items-center gap-3">
            <Gift className="w-5 h-5 text-primary-500" />
            <Text variant="base" className="font-medium">
              Personalizirano video sporočilo
            </Text>
          </div>
          
          {wantsCertificate && (
            <div className="flex items-center gap-3">
              <Star className="w-5 h-5 text-accent-red-500" />
              <Text variant="base" className="font-medium">
                Certifikat pridnosti
              </Text>
            </div>
          )}
          
          {wantsColoringBook && (
            <div className="flex items-center gap-3">
              <Star className="w-5 h-5 text-primary-500" />
              <Text variant="base" className="font-medium">
                Božičkova pobarvanka
              </Text>
            </div>
          )}
        </div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4, duration: 0.5 }}
        className="pt-4"
      >
        <Button
          variant="accent"
          size="lg"
          fullWidth
          onClick={onComplete}
        >
          Zaključi
        </Button>
      </motion.div>
    </motion.div>
  );
}