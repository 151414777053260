import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Card } from '../components/ui/Card';
import { Text } from '../components/ui/Text';
import { Button } from '../components/ui/Button';
import { Loader2, FileText, Calendar, Download, ExternalLink } from 'lucide-react';
import { firebaseService } from '../services/firebase';
import { format } from 'date-fns';
import { sl } from 'date-fns/locale/sl';

interface InvoiceDetails {
  id: string;
  url: string;
  pdfUrl: string;
  amount: number;
  dueDate: Date;
  status: 'pending' | 'paid' | 'failed';
}

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

export function InvoiceStatusPage() {
  const [searchParams] = useSearchParams();
  const [invoice, setInvoice] = useState<InvoiceDetails | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const orderId = searchParams.get('orderId');
    if (!orderId) return;

    const loadInvoice = async (retryCount = 0) => {
      try {
        console.log(`🔄 Loading invoice (attempt ${retryCount + 1}/${MAX_RETRIES})`, {
          orderId
        });

        const order = await firebaseService.getOrderById(orderId);
        
        console.log('📦 Order data:', {
          orderId,
          hasInvoice: !!order?.payment.invoiceUrl,
          paymentStatus: order?.payment.status
        });

        if (!order?.payment.invoiceUrl) {
          if (retryCount < MAX_RETRIES - 1) {
            console.log(`⏳ Retrying in ${RETRY_DELAY}ms...`);
            await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
            return loadInvoice(retryCount + 1);
          }
          throw new Error('Invoice not found after multiple attempts');
        }

        const mapOrderStatusToInvoiceStatus = (
          status: string
        ): 'pending' | 'paid' | 'failed' => {
          switch (status) {
            case 'succeeded':
              return 'paid';
            case 'failed':
              return 'failed';
            default:
              return 'pending';
          }
        };

        const invoiceDetails: InvoiceDetails = {
          id: order.payment.invoiceId!,
          amount: order.payment.amount!,
          dueDate: new Date(order.payment.dueDate!),
          url: order.payment.invoiceUrl,
          pdfUrl: order.payment.invoicePdf!,
          status: mapOrderStatusToInvoiceStatus(order.payment.status)
        };

        console.log('✅ Invoice loaded:', invoiceDetails);
        setInvoice(invoiceDetails);

      } catch (error) {
        console.error('❌ Error loading invoice:', error);
        setError(error instanceof Error ? error.message : 'Failed to load invoice');
      } finally {
        setIsLoading(false);
      }
    };

    loadInvoice();
  }, [searchParams]);

  const formatDate = (date: Date) => {
    try {
      return format(date, 'd. MMMM yyyy', { locale: sl });
    } catch (error) {
      console.error('Date formatting error:', error);
      return date.toLocaleDateString('sl');
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-primary-50 flex items-center justify-center p-4">
        <Loader2 className="w-8 h-8 text-primary-500 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-primary-50 flex items-center justify-center p-4">
        <Card variant="white" className="p-6 w-full max-w-md text-center">
          <Text variant="base" className="text-red-500">{error}</Text>
        </Card>
      </div>
    );
  }

  if (!invoice) return null;

  return (
    <div className="min-h-screen bg-primary-50 flex items-center justify-center p-4">
      <Card variant="white" className="p-6 w-full max-w-md">
        <div className="text-center mb-8">
          <div className="w-16 h-16 rounded-full bg-primary-100 flex items-center justify-center mx-auto mb-4">
            <FileText className="w-8 h-8 text-primary-500" />
          </div>
          <Text variant="large" className="font-semibold">
            Predračun pripravljen
          </Text>
          <Text variant="base" className="text-neutral-600">
            Št. {invoice.id}
          </Text>
        </div>

        <div className="space-y-6">
          {/* Amount */}
          <div className="bg-primary-50 p-4 rounded-xl text-center">
            <Text variant="small" className="text-neutral-600 mb-1">
              Znesek za plačilo
            </Text>
            <Text variant="xlarge" className="font-semibold text-primary-700">
              {invoice.amount.toFixed(2)} €
            </Text>
          </div>

          {/* Due Date */}
          <div className="flex items-center gap-3 p-4 border border-neutral-200 rounded-xl">
            <Calendar className="w-5 h-5 text-primary-500" />
            <div>
              <Text variant="small" className="text-neutral-600">
                Rok plačila
              </Text>
              <Text variant="base" className="font-medium">
                {formatDate(invoice.dueDate)}
              </Text>
            </div>
          </div>

          {/* Actions */}
          <div className="space-y-3 pt-4">
            <Button
              variant="accent"
              size="lg"
              fullWidth
              className="flex items-center justify-center gap-2"
              onClick={() => window.open(invoice.url, '_blank')}
            >
              <ExternalLink className="w-5 h-5" />
              Odpri predračun
            </Button>
            <Button
              variant="secondary"
              size="lg"
              fullWidth
              className="flex items-center justify-center gap-2"
              onClick={() => window.open(invoice.pdfUrl, '_blank')}
            >
              <Download className="w-5 h-5" />
              Prenesi PDF
            </Button>
          </div>

          <Text variant="small" className="text-neutral-500 text-center">
            Povezava do predračuna je bila poslana tudi na vaš email naslov.
          </Text>
        </div>
      </Card>
    </div>
  );
} 