import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { HomePage } from './pages/HomePage';
import { ChildDetailsPage } from './pages/ChildDetailsPage';
import { PaymentPage } from './pages/PaymentPage';
import { PaymentCompletePage } from './pages/PaymentCompletePage';
import { CompletionWrapper } from './components/Payment/StripeWrappers';
import { TestFirebase } from './components/TestFirebase';
import { InvoiceStatusPage } from './pages/InvoiceStatusPage';
import { QuoteStatusPage } from './pages/QuoteStatusPage';

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />
  },
  {
    path: '/order/children',
    element: <ChildDetailsPage />
  },
  {
    path: '/payment',
    element: <PaymentPage />
  },
  {
    path: '/payment/complete',
    element: (
      <CompletionWrapper>
        <PaymentCompletePage />
      </CompletionWrapper>
    )
  },
  {
    path: '/payment/invoice',
    element: <InvoiceStatusPage />
  },
  {
    path: '/test-firebase',
    element: <TestFirebase />
  },
  {
    path: '/payment/quote-status',
    element: <QuoteStatusPage />
  }
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;