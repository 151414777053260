import React, { InputHTMLAttributes } from 'react';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  onComplete?: () => void;
  inputSize?: 'default' | 'large';
}

export function Input({ className = '', error, onComplete, inputSize = 'default', ...props }: InputProps) {
  const sizeClasses = {
    default: 'h-14 px-4 text-base',
    large: 'h-16 px-5 text-lg'
  };

  return (
    <div>
      <input
        className={`
          w-full
          rounded-xl
          border-2
          border-neutral-300
          text-neutral-900
          placeholder:text-neutral-500
          ${sizeClasses[inputSize]}
          focus:border-primary-500
          focus:ring-1
          focus:ring-primary-500
          focus:ring-offset-2
          outline-none
          transition-colors
          touch-manipulation
          selection:bg-primary-100
          ${error ? 'border-semantic-error' : ''}
          ${className}
        `}
        enterKeyHint="next"
        onKeyUp={(e) => {
          if (e.key === 'Enter' && onComplete) {
            onComplete();
          }
        }}
        aria-invalid={error ? 'true' : 'false'}
        {...props}
      />
      {error && (
        <p className="mt-2 text-base text-semantic-error font-medium" role="alert">
          {error}
        </p>
      )}
    </div>
  );
}