import React from 'react';
import { ChevronLeft } from 'lucide-react';
import { Text } from './ui/Text';

interface HeaderProps {
  showBack?: boolean;
  onBack?: () => void;
  title?: string;
  subtitle?: string;
  progress?: {
    current: number;
    total: number;
  };
}

export function Header({ 
  showBack = false, 
  onBack, 
  title, 
  subtitle,
  progress 
}: HeaderProps) {
  return (
    <header className="fixed top-0 left-0 right-0 bg-white z-50 shadow-sm">
      {/* Main Header Bar */}
      <div className="flex items-center h-14 px-4">
        {showBack && (
          <button
            onClick={onBack}
            className="flex items-center justify-center w-10 h-10 -ml-2 rounded-full hover:bg-neutral-50 active:bg-neutral-100 transition-colors"
          >
            <ChevronLeft className="w-5 h-5 text-neutral-600" />
          </button>
        )}
        
        <div className="flex-1 min-w-0 px-2">
          {title && (
            <Text 
              variant="base" 
              className="font-semibold text-neutral-900 truncate"
            >
              {title}
            </Text>
          )}
          {subtitle && (
            <Text 
              variant="small" 
              className="text-neutral-500 truncate"
            >
              {subtitle}
            </Text>
          )}
        </div>
      </div>

      {/* Progress Bar - Only shown when progress is provided */}
      {progress && (
        <div className="h-1 bg-neutral-100">
          <div 
            className="h-full bg-primary-500 transition-all duration-300"
            style={{ 
              width: `${(progress.current / progress.total) * 100}%` 
            }}
          />
        </div>
      )}
    </header>
  );
}