import { useState } from 'react';
import { ChildData } from '../types/child';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from '../components/PageHeader';
import { ChildCounter } from '../components/ChildCounter';
import { ChildFormModal } from '../components/ChildFormModal/ChildFormModal';
import { PriceCard } from '../components/PriceCard';
import { Card } from '../components/ui/Card';
import { Text } from '../components/ui/Text';
import { Button } from '../components/ui/Button';
import { CheckCircle, ChevronRight, PenLine } from 'lucide-react';
import { motion } from 'framer-motion';
import { Layout } from '../components/Layout';

export function ChildDetailsPage() {
  const navigate = useNavigate();
  const [childCount, setChildCount] = useState(1);
  const [activeChildIndex, setActiveChildIndex] = useState<number | null>(null);
  const [completedChildren, setCompletedChildren] = useState<Set<number>>(new Set());
  const [childrenData, setChildrenData] = useState<Record<number, ChildData>>({});

  // Calculate number of certificates and coloring books selected
  const certificateCount = Object.values(childrenData)
    .filter((child: ChildData) => child.wantsCertificate).length;
  const coloringBookCount = Object.values(childrenData)
    .filter((child: ChildData) => child.wantsColoringBook).length;

  const handleBack = () => {
    navigate(-1);
  };

  const handleChildCountChange = (newCount: number) => {
    // If reducing child count, remove data for removed children
    if (newCount < childCount) {
      setChildrenData((prev: Record<number, ChildData>) => {
        const newData = { ...prev };
        for (let i = newCount; i < childCount; i++) {
          delete newData[i];
        }
        return newData;
      });
      setCompletedChildren((prev: Set<number>) => {
        const newCompleted = new Set(prev);
        for (let i = newCount; i < childCount; i++) {
          newCompleted.delete(i);
        }
        return newCompleted;
      });
    }
    setChildCount(newCount);
  };

  const handleContinue = () => {
    if (completedChildren.size === childCount) {
      // Save data to localStorage before navigating
      localStorage.setItem('orderData', JSON.stringify({
        childCount,
        childrenData,
        certificateCount,
        coloringBookCount
      }));
      
      // Navigate to payment page
      navigate('/payment');
    } else {
      // Open modal for first incomplete child
      for (let i = 0; i < childCount; i++) {
        if (!completedChildren.has(i)) {
          setActiveChildIndex(i);
          break;
        }
      }
    }
  };

  const handleChildClick = (index: number) => {
    setActiveChildIndex(index);
  };

  const handleModalClose = (data?: ChildData) => {
    if (activeChildIndex !== null && data) {
      setChildrenData(prev => ({
        ...prev,
        [activeChildIndex]: {
          ...data,
          wantsCertificate: data.wantsCertificate || false,
          wantsColoringBook: data.wantsColoringBook || false
        }
      }));
      setCompletedChildren(prev => new Set(prev).add(activeChildIndex));
    }
    setActiveChildIndex(null);
  };

  return (
    <Layout 
      showHeader={false} 
      variant="light"
    >
      <PageHeader 
        step={1} 
        totalSteps={3} 
        title="Vaši otroci" 
        onBack={handleBack}
      />

      <motion.main 
        className="space-y-4 xs:space-y-6 sm:space-y-8"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        {/* Visual Hierarchy with Sections */}
        <div className="space-y-4 xs:space-y-6 sm:space-y-8">
          {/* Counter Section */}
          <motion.section 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
          >
            <Card variant="white" className="p-4 sm:p-6">
              <Text variant="large" className="font-semibold mb-6">
                Koliko otrok želite razveseliti?
              </Text>
              <ChildCounter count={childCount} onChange={handleChildCountChange} />
            </Card>
          </motion.section>

          {/* Children List Section */}
          <section className="space-y-4">
            {Array.from({ length: childCount }).map((_, index) => {
              const isCompleted = completedChildren.has(index);
              const childData = childrenData[index];
              
              return (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.2 + (index * 0.1) }}
                >
                  <Card 
                    variant="white" 
                    className={`
                      p-6 transition-all duration-300
                      ${isCompleted ? 
                        'bg-primary-50 border-2 border-primary-500 shadow-md' : 
                        'hover:border-primary-200'
                      }
                    `}
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-3">
                        {isCompleted ? (
                          <CheckCircle className="w-6 h-6 text-primary-500" />
                        ) : (
                          <div className="w-6 h-6 rounded-full border-2 border-neutral-200" />
                        )}
                        <div>
                          <Text variant="base" className="font-semibold text-neutral-900">
                            {isCompleted ? childData?.name : `Otrok ${index + 1}`}
                          </Text>
                          <Text variant="small" className="text-neutral-500">
                            {isCompleted ? (
                              <span>
                                {childData?.age} let
                                {childData?.wantsCertificate && ' • Certifikat dodan'}
                                {childData?.wantsColoringBook && ' • Pobarvanka dodana'}
                              </span>
                            ) : (
                              'Klikni za vnos podatkov'
                            )}
                          </Text>
                        </div>
                      </div>
                      
                      <Button
                        variant={isCompleted ? 'secondary' : 'primary'}
                        size="sm"
                        onClick={() => handleChildClick(index)}
                        className="flex items-center gap-2"
                      >
                        {isCompleted ? (
                          <>
                            <PenLine className="w-4 h-4" />
                            <span>Uredi</span>
                          </>
                        ) : (
                          <>
                            <span>Izpolni</span>
                            <ChevronRight className="w-4 h-4" />
                          </>
                        )}
                      </Button>
                    </div>
                  </Card>
                </motion.div>
              );
            })}
          </section>

          {/* Price Summary Section */}
          <motion.section
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 + (childCount * 0.1) }}
          >
            <PriceCard 
              childCount={childCount} 
              certificateCount={certificateCount}
              coloringBookCount={coloringBookCount}
            />
          </motion.section>
        </div>
      </motion.main>

      {/* Fixed Button - Animate from bottom */}
      <motion.div 
        className="fixed bottom-4 sm:bottom-6 md:bottom-8 left-0 right-0 px-3 xs:px-4 sm:px-6 max-w-2xl mx-auto"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4 + (childCount * 0.1) }}
      >
        <Button
          variant="accent"
          size="lg"
          fullWidth
          onClick={handleContinue}
        >
          {completedChildren.size === childCount ? 'Nadaljuj na plačilo' : 'Izpolni podatke'}
        </Button>
      </motion.div>

      {/* Modal remains unchanged */}
      {activeChildIndex !== null && (
        <ChildFormModal
          isOpen={true}
          onClose={handleModalClose}
          childNumber={activeChildIndex + 1}
          totalChildren={childCount}
          initialData={childrenData[activeChildIndex]}
        />
      )}
    </Layout>
  );
}