import { useEffect, useState } from 'react';
import { firebaseService } from '../services/firebase';

export function TestFirebase() {
  const [isConnected, setIsConnected] = useState<boolean | null>(null);

  useEffect(() => {
    const testConnection = async () => {
      const result = await firebaseService.testConnection();
      setIsConnected(result);
    };

    testConnection();
  }, []);

  return (
    <div>
      <h2>Firebase Connection Status:</h2>
      {isConnected === null ? (
        <p>Testing connection...</p>
      ) : isConnected ? (
        <p style={{ color: 'green' }}>Connected to Firebase!</p>
      ) : (
        <p style={{ color: 'red' }}>Failed to connect to Firebase</p>
      )}
    </div>
  );
} 