import React from 'react';
import { motion } from 'framer-motion';
import { Award, CheckCircle2, Sparkles } from 'lucide-react';
import { Text } from '../ui/Text';
import { Button } from '../ui/Button';

interface CertificateUpsellProps {
  childName: string;
  onSelect: (wantsCertificate: boolean) => void;
  selected?: boolean;
}

export function CertificateUpsell({
  childName,
  onSelect,
  selected = false
}: CertificateUpsellProps) {
  return (
    <motion.div 
      className="space-y-6"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="text-center">
        <div className="relative inline-block mb-4">
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ 
              type: "spring",
              duration: 0.8,
              bounce: 0.5
            }}
            className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-accent-red-50 text-accent-red-500"
          >
            <Award className="w-8 h-8" />
          </motion.div>
          
          {/* Sparkle animations */}
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.3, duration: 0.5 }}
            className="absolute -top-2 -right-2"
          >
            <Sparkles className="w-4 h-4 text-accent-gold-500" />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.5, duration: 0.5 }}
            className="absolute -bottom-1 -left-2"
          >
            <Sparkles className="w-4 h-4 text-accent-gold-500" />
          </motion.div>
        </div>
        
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <Text variant="large" className="font-semibold">
            Posebno darilo za {childName}
          </Text>
        </motion.div>
      </div>

      <motion.div 
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.3, duration: 0.5 }}
        className={`
          relative p-4 rounded-xl border-2 transition-all
          ${selected 
            ? 'border-accent-red-500 bg-accent-red-50' 
            : 'border-neutral-200'
          }
        `}
      >
        {/* Certificate Preview */}
        <motion.div 
          className="bg-white rounded-lg p-4 shadow-sm mb-4"
          whileHover={{ scale: 1.02 }}
          transition={{ type: "spring", stiffness: 300 }}
        >
          <Text variant="base" className="font-semibold text-center mb-2">
            Certifikat Pridnosti
          </Text>
          <Text variant="small" className="text-neutral-600 text-center">
            Podpisan s strani Božička z uradnim pečatom
          </Text>
        </motion.div>

        {/* Selection Indicator */}
        <div className="absolute top-3 right-3">
          <motion.div 
            className={`
              w-6 h-6 rounded-full border-2 flex items-center justify-center
              transition-colors
              ${selected 
                ? 'border-accent-red-500 bg-accent-red-500' 
                : 'border-neutral-300'
              }
            `}
            initial={false}
            animate={selected ? { scale: [1, 1.2, 1] } : {}}
            transition={{ duration: 0.3 }}
          >
            {selected && <CheckCircle2 className="w-4 h-4 text-white" />}
          </motion.div>
        </div>

        {/* Price Tag */}
        <div className="flex items-center justify-between">
          <Text variant="base" className="font-medium">
            Personaliziran certifikat
          </Text>
          <div className="text-right">
            <Text variant="base" className="font-semibold text-accent-red-500">
              3,50 €
            </Text>
            <Text variant="small" className="text-neutral-500">
              DDV vključen
            </Text>
          </div>
        </div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4, duration: 0.5 }}
        className="space-y-4"
      >
        <Text variant="small" className="text-neutral-500 text-center">
          Certifikat lahko natisnete sami takoj po prejemu.
        </Text>

        <div className="flex flex-col gap-3">
          {selected ? (
            <>
              <Button
                variant="accent"
                size="lg"
                fullWidth
                onClick={() => onSelect(false)}
              >
                Odstrani iz naročila
              </Button>
              <Button
                variant="primary"
                size="lg"
                fullWidth
                onClick={() => onSelect(true)}
              >
                Nadaljuj
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="accent"
                size="lg"
                fullWidth
                onClick={() => onSelect(true)}
              >
                Da, dodaj certifikat (3,50 €)
              </Button>
              <Button
                variant="secondary"
                size="lg"
                fullWidth
                onClick={() => onSelect(false)}
              >
                Ne, hvala
              </Button>
            </>
          )}
        </div>
      </motion.div>
    </motion.div>
  );
}