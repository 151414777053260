// Design Tokens - The single source of truth for our design system

import { typography } from "./typography";

export const tokens = {
  colors: {
    // Brand Colors
    primary: {
      50: '#F5F9F6',
      100: '#E1EDE4',
      200: '#C2DAC8',
      300: '#94BC9E',
      400: '#669D74',
      500: '#2D633D', // Primary brand color
      600: '#234F31',
      700: '#1A3B24',
      800: '#112717',
      900: '#091309',
    },
    // Accent Colors - Festive
    accent: {
      red: {
        50: '#FEF2F2',
        100: '#FEE2E2',
        500: '#EF4444', // Primary accent
        600: '#DC2626',
        700: '#B91C1C',
      },
      gold: {
        50: '#FFFBEB',
        100: '#FEF3C7',
        500: '#F59E0B',
        600: '#D97706',
        700: '#B45309',
      },
    },
    // Neutral Colors
    neutral: {
      50: '#F9FAFB',
      100: '#F3F4F6',
      200: '#E5E7EB',
      300: '#D1D5DB',
      400: '#9CA3AF',
      500: '#6B7280',
      600: '#4B5563',
      700: '#374151',
      800: '#1F2937',
      900: '#111827',
    },
    // Semantic Colors
    semantic: {
      success: '#059669',
      error: '#DC2626',
      warning: '#D97706',
      info: '#2563EB',
    },
  },

  spacing: {
    0: '0',
    1: '4px',
    2: '8px',
    3: '12px',
    4: '16px',
    5: '20px',
    6: '24px',
    8: '32px',
    10: '40px',
    12: '48px',
    16: '64px',
    20: '80px',
    24: '96px',
  },

  typography: {
    fonts: {
      sans: typography.fontFamily.sans,
    },
    sizes: {
      xs: 'var(--text-xs)',
      sm: 'var(--text-sm)',
      base: '16px',
      lg: '18px',
      xl: '20px',
      '2xl': '24px',
      '3xl': '30px',
      '4xl': '36px',
      '5xl': '48px',
    },
    weights: {
      light: '300',
      normal: '400',
      medium: '500',
      semibold: '600',
      bold: '700',
      extrabold: '800'
    },
    lineHeights: {
      tight: '1.25',
      base: '1.5',
      relaxed: '1.75',
    },
  },

  radius: {
    sm: '4px',
    base: '8px',
    md: '12px',
    lg: '16px',
    xl: '24px',
    '2xl': '32px',
    full: '9999px',
  },

  shadows: {
    sm: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
    base: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
    md: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
    lg: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
  },
} as const;