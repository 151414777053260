import { components } from '../../styles/components';

interface TextProps {
  variant: 'small' | 'base' | 'large' | 'xlarge';
  className?: string;
  children: React.ReactNode;
}

export function Text({
  variant = 'base',
  className = '',
  children,
}: TextProps) {
  // Map our variants to the design system styles
  const getVariantStyle = (variant: TextProps['variant']) => {
    switch (variant) {
      case 'small':
        return components.text.body.small;
      case 'base':
        return components.text.body.base;
      case 'large':
        return components.text.body.large;
      case 'xlarge':
        return 'text-2xl font-semibold'; // Custom style for xlarge
      default:
        return components.text.body.base;
    }
  };
  
  return (
    <div className={`${getVariantStyle(variant)} ${className}`}>
      {children}
    </div>
  );
}