import React, { TextareaHTMLAttributes } from 'react';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: string;
  onComplete?: () => void;
  inputSize?: 'default' | 'large';
}

export function TextArea({ 
  className = '', 
  error, 
  onComplete,
  inputSize = 'default',
  ...props 
}: TextAreaProps) {
  const sizeClasses = {
    default: 'px-4 py-3 text-base',
    large: 'px-5 py-4 text-lg'
  };

  return (
    <div>
      <textarea
        className={`
          w-full
          border-2
          border-neutral-300
          text-neutral-900
          placeholder:text-neutral-500
          ${sizeClasses[inputSize]}
          focus:border-primary-500
          focus:ring-1
          focus:ring-primary-500
          focus:ring-offset-2
          outline-none
          transition-colors
          resize-none
          touch-manipulation
          selection:bg-primary-100
          ${error ? 'border-semantic-error' : ''}
          ${className}
        `}
        enterKeyHint="done"
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.shiftKey && onComplete) {
            e.preventDefault();
            onComplete();
          }
        }}
        aria-invalid={error ? 'true' : 'false'}
        {...props}
      />
      {error && (
        <p className="mt-2 text-base text-semantic-error font-medium" role="alert">
          {error}
        </p>
      )}
    </div>
  );
} 