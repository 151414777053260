import React, { ButtonHTMLAttributes } from 'react';
import { components } from '../../styles/components';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: keyof typeof components.button.variants;
  size?: keyof typeof components.button.sizes;
  fullWidth?: boolean;
}

export function Button({
  variant = 'primary',
  size = 'md',
  fullWidth = false,
  className = '',
  children,
  ...props
}: ButtonProps) {
  const baseStyles = components.button.base;
  const variantStyles = components.button.variants[variant];
  const sizeStyles = components.button.sizes[size];
  
  return (
    <button
      className={`
        ${baseStyles}
        ${variantStyles}
        ${sizeStyles}
        ${fullWidth ? 'w-full' : ''}
        ${className}
      `}
      {...props}
    >
      {children}
    </button>
  );
}