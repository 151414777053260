export const typography = {
  fontFamily: {
    // System font stack - more reliable across devices
    sans: [
      'Open Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol'
    ].join(', '),
    // Fallback system fonts
    fallback: [
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif'
    ].join(', ')
  },
  
  // Font feature settings
  features: {
    sans: {
      normal: {
        display: 'swap',
        fontFeatureSettings: '"kern" 1, "liga" 1',
        fontSmooth: 'always',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        fontOpticalSizing: 'auto',
        fontVariationSettings: '"wdth" 100'
      }
    }
  }
}; 