import React from 'react';
import { Text } from '../ui/Text';
import { Input } from '../ui/Input';
import { TextArea } from '../ui/TextArea';
import { AchievementsGrid } from './AchievementsGrid';
import { Button } from '../ui/Button';

type FormValue = string | string[];

interface ChildFormStepProps {
  step: number;
  formData: {
    name: string;
    age: string;
    giftWish: string;
    interests: string;
    achievements: string[];
  };
  onChange: (key: string, value: FormValue) => void;
  onNext: () => void;
}

export function ChildFormStep({ 
  step, 
  formData, 
  onChange, 
  onNext 
}: ChildFormStepProps) {
  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="space-y-6">
            <Text variant="large" className="font-semibold">
              Živjo! Kako je otroku ime?
            </Text>
            <Input
              type="text"
              value={formData.name}
              onChange={e => onChange('name', e.target.value)}
              placeholder="Vpišite ime"
              enterKeyHint="next"
              inputSize="large"
              aria-label="Ime otroka"
            />
          </div>
        );
      
      case 2:
        return (
          <div className="space-y-6">
            <Text variant="large" className="font-semibold">
              Koliko je {formData.name} star/a?
            </Text>
            <div className="space-y-4">
              <div>
                <Input
                  type="number"
                  value={formData.age}
                  onChange={e => {
                    const value = e.target.value;
                    if (value === '' || (Number(value) >= 1 && Number(value) <= 12)) {
                      onChange('age', value);
                    }
                  }}
                  placeholder="Leta"
                  min={1}
                  max={12}
                  pattern="[0-9]*"
                  inputMode="numeric"
                  enterKeyHint="next"
                  inputSize="large"
                  aria-label="Starost otroka"
                />
                <Text variant="small" className="text-neutral-500 mt-2">
                  Vnesite starost med 1 in 12 let
                </Text>
              </div>
            </div>
          </div>
        );
      
      case 3:
        return (
          <div className="space-y-4">
            <Text variant="large" className="font-semibold">
              Kaj si {formData.name} želi za božično darilo?
            </Text>
            <TextArea
              value={formData.giftWish}
              onChange={e => onChange('giftWish', e.target.value)}
              placeholder="Na primer: nova igračka, knjiga, igra..."
              inputSize="large"
              className="min-h-[120px]"
              aria-label="Božična želja"
              onComplete={() => {
                if (formData.giftWish.length > 0) {
                  onNext();
                }
              }}
            />
          </div>
        );
      
      case 4:
        return (
          <div className="space-y-4">
            <Text variant="large" className="font-semibold">
              Kaj {formData.name} rad/a počne?
            </Text>
            <TextArea
              value={formData.interests}
              onChange={e => onChange('interests', e.target.value)}
              placeholder="Na primer:
• Nogomet - igra za šolski klub
• Klavir - hodi na glasbeno šolo
• Rad riše in ustvarja
• Obožuje dinozavre"
              className="min-h-[120px]"
              onComplete={() => {
                if (formData.interests.length > 0) {
                  onNext();
                }
              }}
            />
          </div>
        );
      
      case 5:
        return (
          <div className="space-y-6">
            <Text variant="large" className="font-semibold">
              Kaj bi radi v videu posebej izpostavili?
            </Text>
            <Text variant="small" className="text-neutral-500">
              Kje se je {formData.name} še posebej dobro izkazal/a?
            </Text>
            <AchievementsGrid
              selected={formData.achievements || []}
              onChange={value => onChange('achievements', value)}
            />
          </div>
        );
      
      default:
        return null;
    }
  };

  return (
    <div className="min-h-[300px] relative pb-20">
      {renderStep()}
      {step < 6 && (
        <div className="
          absolute bottom-0 left-0 right-0
          p-4
          bg-gradient-to-t from-white via-white to-transparent
          -mx-6
        ">
          {step === 1 && (
            <Button
              variant="primary"
              size="lg"
              onClick={onNext}
              disabled={!formData.name.length}
              fullWidth
            >
              Naprej
            </Button>
          )}
          {step === 2 && (
            <Button
              variant="primary"
              size="lg"
              onClick={onNext}
              disabled={!formData.age || Number(formData.age) < 1 || Number(formData.age) > 12}
              fullWidth
            >
              Naprej
            </Button>
          )}
          {step === 3 && (
            <Button
              variant="primary"
              size="lg"
              onClick={onNext}
              disabled={!formData.giftWish.length}
              fullWidth
            >
              Naprej
            </Button>
          )}
          {step === 4 && (
            <Button
              variant="primary"
              size="lg"
              onClick={onNext}
              disabled={!formData.interests.length}
              fullWidth
            >
              Naprej
            </Button>
          )}
          {step === 5 && formData.achievements.length > 0 && (
            <Button
              variant="primary"
              size="lg"
              onClick={onNext}
              fullWidth
            >
              Naprej
            </Button>
          )}
        </div>
      )}
    </div>
  );
}