import { useNavigate } from 'react-router-dom';
import { Layout } from '../components/Layout';
import { ProcessSteps } from '../components/ProcessSteps';
import { StartButton } from '../components/StartButton';

export function HomePage() {
  const navigate = useNavigate();

  const handleStart = () => {
    navigate('/order/children');
  };

  return (
    <Layout showHeader={false} variant="primary">
      <ProcessSteps />
      <StartButton onClick={handleStart} />
    </Layout>
  );
}