import React, { useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Text } from '../ui/Text';
import { Button } from '../ui/Button';
import { Lock, ShieldCheck, CreditCard, Wallet } from 'lucide-react';

interface StripePaymentProps {
  amount: number;
  onError: (error: string) => void;
  onCancel: () => void;
  customerInfo?: {
    name: string;
    email: string;
    phone?: string;
    address: {
      line1: string;
      postal_code: string;
      city: string;
      country: string;
    };
  };
}

export function StripePayment({ amount, onError, onCancel, customerInfo }: StripePaymentProps) {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    setIsLoading(true);

    try {
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/payment/complete`,
          payment_method_data: customerInfo ? {
            billing_details: customerInfo
          } : undefined,
        },
      });

      if (error) {
        onError(error.message || 'Payment failed');
      }
    } catch {
      onError('An unexpected error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-center gap-2 bg-primary-50 p-3 rounded-lg">
        <ShieldCheck className="w-5 h-5 text-primary-500" />
        <Text variant="small" className="font-medium text-primary-700">
          Varno plačilo s SSL šifriranjem
        </Text>
      </div>

      <form onSubmit={handleSubmit} className="space-y-8">
        <div className="flex items-center gap-3 pb-4 border-b border-neutral-200">
          <CreditCard className="w-6 h-6 text-primary-500" />
          <div>
            <Text variant="base" className="font-semibold">
              Plačilo s kartico
            </Text>
            <Text variant="small" className="text-neutral-500">
              Varen način plačila preko Stripe
            </Text>
          </div>
        </div>

        <div className="bg-neutral-50 p-4 rounded-lg">
          <Text variant="small" className="text-neutral-600 mb-1">
            Znesek za plačilo
          </Text>
          <Text variant="large" className="font-semibold text-2xl">
            {amount.toFixed(2)} €
          </Text>
        </div>

        <div className="space-y-6">
          <PaymentElement />
        </div>

        <div className="space-y-3">
          <Button
            variant="accent"
            size="lg"
            fullWidth
            type="submit"
            disabled={isLoading || !stripe}
            className="flex items-center justify-center gap-2"
          >
            <Lock className="w-4 h-4" />
            {isLoading ? 'Procesiranje...' : 'Plačaj varno'}
          </Button>
          
          <Button
            variant="secondary"
            size="lg"
            fullWidth
            onClick={onCancel}
            disabled={isLoading}
          >
            Prekliči
          </Button>

          <div className="flex items-center justify-center gap-6 pt-4 border-t border-neutral-200">
            <div className="flex items-center gap-2 text-primary-500">
              <Wallet className="w-5 h-5" />
              <Text variant="small" className="font-medium">Stripe</Text>
            </div>
            <div className="flex items-center gap-2 text-primary-500">
              <ShieldCheck className="w-5 h-5" />
              <Text variant="small" className="font-medium">SSL Secure</Text>
            </div>
          </div>
        </div>
      </form>

      <Text variant="small" className="text-neutral-600 text-center">
        Vaši podatki so varni in šifrirani. Za več informacij o varnosti plačil obiščite našo 
        <a href="/privacy" className="text-primary-500 hover:text-primary-600 ml-1">
          politiko zasebnosti
        </a>.
      </Text>
    </div>
  );
}