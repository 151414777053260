import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { firebaseService } from '../services/firebase';
import type { PaymentStatus } from '../types/firebase';
import { Card } from '../components/ui/Card';
import { Text } from '../components/ui/Text';
import { Button } from '../components/ui/Button';
import { 
  Loader2, 
  CheckCircle2, 
  AlertCircle 
} from 'lucide-react';

export function PaymentCompletePage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState<PaymentStatus>('loading');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const updateOrderStatus = async () => {
      try {
        const paymentIntentId = searchParams.get('payment_intent');
        console.log('🔍 Starting payment check:', {
          paymentIntentId,
          status,
          timestamp: new Date().toISOString()
        });

        if (!paymentIntentId) {
          throw new Error('No payment intent ID found');
        }

        // Get order by payment intent
        const order = await firebaseService.getOrderByPaymentIntentId(paymentIntentId);
        
        console.log('📦 Order status:', {
          orderId: order?.id,
          paymentStatus: order?.payment?.status,
          orderStatus: order?.status,
          paymentIntent: paymentIntentId
        });

        if (!order) {
          // Add delay to wait for webhook processing
          await new Promise(resolve => setTimeout(resolve, 3000));
          
          // Try one more time
          const retryOrder = await firebaseService.getOrderByPaymentIntentId(paymentIntentId);
          
          if (!retryOrder) {
            throw new Error('Order not found');
          }
          
          console.log('📦 Retry order status:', {
            orderId: retryOrder.id,
            paymentStatus: retryOrder.payment?.status,
            orderStatus: retryOrder.status,
            paymentIntent: paymentIntentId
          });

          if (retryOrder.payment?.status === 'succeeded') {
            setStatus('succeeded');
          } else {
            await new Promise(resolve => setTimeout(resolve, 2000));
            const finalRetry = await firebaseService.getOrderByPaymentIntentId(paymentIntentId);
            if (finalRetry?.payment?.status === 'succeeded') {
              setStatus('succeeded');
            } else {
              setStatus('failed');
            }
          }
        } else {
          if (order.payment?.status === 'succeeded') {
            setStatus('succeeded');
          } else {
            await new Promise(resolve => setTimeout(resolve, 2000));
            const retryOrder = await firebaseService.getOrderByPaymentIntentId(paymentIntentId);
            if (retryOrder?.payment?.status === 'succeeded') {
              setStatus('succeeded');
            } else {
              setStatus('failed');
            }
          }
        }

      } catch (error) {
        console.error('❌ Failed to update order:', error);
        setError(error instanceof Error ? error.message : 'Unknown error');
        setStatus('failed');
      }
    };

    updateOrderStatus();
  }, [searchParams]);

  return (
    <div className="min-h-screen bg-primary-50 flex flex-col items-center justify-center p-4">
      <Card variant="white" className="p-8 max-w-md w-full">
        {status === 'loading' && (
          <div className="text-center">
            <Loader2 className="w-12 h-12 text-primary-500 animate-spin mx-auto mb-4" />
            <Text variant="large" className="font-semibold mb-2">
              Preverjanje plačila...
            </Text>
            <Text variant="base" className="text-neutral-600">
              Prosimo počakajte, preverjamo status vašega plačila.
            </Text>
          </div>
        )}

        {status === 'succeeded' && (
          <div className="text-center">
            <CheckCircle2 className="w-12 h-12 text-green-500 mx-auto mb-4" />
            <Text variant="large" className="font-semibold mb-4">
              Naročilo uspešno zaključeno!
            </Text>
            <div className="space-y-4">
              <Text variant="base" className="text-neutral-600">
                Hvala za vaše naročilo. Na vaš email naslov boste prejeli:
              </Text>
              <ul className="text-left space-y-2 mb-6">
                <li className="flex items-center gap-2 text-neutral-700">
                  <div className="w-1.5 h-1.5 bg-primary-500 rounded-full" />
                  Potrdilo o plačilu
                </li>
                <li className="flex items-center gap-2 text-neutral-700">
                  <div className="w-1.5 h-1.5 bg-primary-500 rounded-full" />
                  Podrobnosti naročila
                </li>
                <li className="flex items-center gap-2 text-neutral-700">
                  <div className="w-1.5 h-1.5 bg-primary-500 rounded-full" />
                  Video sporočilo (v nekaj urah)
                </li>
              </ul>
              <Button
                variant="primary"
                size="lg"
                fullWidth
                onClick={() => navigate('/')}
              >
                Nazaj na domačo stran
              </Button>
            </div>
          </div>
        )}

        {status === 'processing' && (
          <div className="text-center">
            <Loader2 className="w-12 h-12 text-primary-500 animate-spin mx-auto mb-4" />
            <Text variant="large" className="font-semibold mb-2">
              Plačilo se obdeluje
            </Text>
            <Text variant="base" className="text-neutral-600">
              Vaše plačilo se trenutno obdeluje. O statusu vas bomo obvestili preko emaila.
            </Text>
          </div>
        )}

        {status === 'failed' && (
          <div className="text-center">
            <AlertCircle className="w-12 h-12 text-red-500 mx-auto mb-4" />
            <Text variant="large" className="font-semibold mb-2">
              Plačilo ni uspelo
            </Text>
            {error && (
              <div className="bg-red-50 text-red-700 p-4 rounded-md mb-4">
                {error}
              </div>
            )}
            <Text variant="base" className="text-neutral-600 mb-6">
              Prišlo je do napake pri plačilu. Prosimo, poskusite ponovno.
            </Text>
            <Button
              variant="primary"
              size="lg"
              fullWidth
              onClick={() => navigate('/payment')}
            >
              Poskusi ponovno
            </Button>
          </div>
        )}

        {status === 'processing_email' && (
          <div className="text-center">
            <Loader2 className="w-12 h-12 text-primary-500 animate-spin mx-auto mb-4" />
            <Text variant="large" className="font-semibold mb-2">
              Pošiljanje potrditve...
            </Text>
            <Text variant="base" className="text-neutral-600">
              Pripravljamo potrditev naročila.
            </Text>
          </div>
        )}
      </Card>
    </div>
  );
} 