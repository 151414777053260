import React from 'react';
import { Card } from './ui/Card';
import { Text } from './ui/Text';
import { calculatePrice } from '../utils/pricing';

interface PriceCardProps {
  childCount: number;
  certificateCount?: number;
  coloringBookCount?: number;
}

export function PriceCard({ 
  childCount, 
  certificateCount = 0,
  coloringBookCount = 0
}: PriceCardProps) {
  const { 
    total, 
    savings, 
    originalTotal, 
    certificatesTotal, 
    coloringBooksTotal,
    grandTotal 
  } = calculatePrice(childCount, certificateCount, coloringBookCount);
  
  const hasDiscount = savings > 0;
  const hasAddons = certificateCount > 0 || coloringBookCount > 0;

  return (
    <Card variant="white" className="p-6">
      <div className="space-y-4">
        {/* Video Messages */}
        <div className="flex items-center justify-between">
          <Text variant="base" className="text-neutral-600">
            Video sporočilo za {childCount} {childCount === 1 ? 'otroka' : 'otroke'}:
          </Text>
          <div className="text-right">
            {hasDiscount && (
              <Text variant="small" className="text-neutral-500 line-through">
                {originalTotal} €
              </Text>
            )}
            <Text variant="large" className="font-semibold text-primary-500">
              {total} €
            </Text>
          </div>
        </div>

        {/* Certificates if any */}
        {certificateCount > 0 && (
          <div className="flex items-center justify-between pt-2 border-t border-neutral-100">
            <Text variant="base" className="text-neutral-600">
              {certificateCount}× Certifikat pridnosti:
            </Text>
            <Text variant="base" className="font-semibold">
              {certificatesTotal} €
            </Text>
          </div>
        )}

        {/* Coloring Books if any */}
        {coloringBookCount > 0 && (
          <div className="flex items-center justify-between pt-2 border-t border-neutral-100">
            <Text variant="base" className="text-neutral-600">
              {coloringBookCount}× Božičkova pobarvanka:
            </Text>
            <Text variant="base" className="font-semibold">
              {coloringBooksTotal} €
            </Text>
          </div>
        )}

        {/* Total */}
        {hasAddons && (
          <div className="flex items-center justify-between pt-2 border-t border-neutral-100">
            <Text variant="base" className="font-semibold">
              Skupaj:
            </Text>
            <Text variant="large" className="font-semibold text-primary-500">
              {grandTotal} €
            </Text>
          </div>
        )}

        {/* Savings Badge */}
        {hasDiscount && (
          <div className="bg-accent-red-50 rounded-xl p-4">
            <Text variant="small" className="text-accent-red-600">
              Prihranek: {savings} €
            </Text>
          </div>
        )}

        <Text variant="small" className="text-neutral-500">
          Vključuje personalizirano video sporočilo
          {certificateCount > 0 && ', certifikat pridnosti'}
          {coloringBookCount > 0 && ', božičkovo pobarvanko'}
        </Text>
      </div>
    </Card>
  );
}