import { 
  collection, 
  doc, 
  setDoc, 
  getDocs, 
  query, 
  where,
  orderBy,
  Timestamp,
  serverTimestamp,
  limit,
  getDoc
} from 'firebase/firestore';
import { db } from '../config/firebase';
import type { Customer, Child, Order, PaymentUpdate } from '../types/firebase';

export const firebaseService = {
  // Test connection
  async testConnection(): Promise<boolean> {
    try {
      const testRef = collection(db, 'customers');
      await getDocs(query(testRef, limit(1)));
      console.log('Firebase connection successful');
      return true;
    } catch (error) {
      console.error('Firebase connection failed:', error);
      return false;
    }
  },

  // Customer methods
  async createCustomer(customerData: Omit<Customer, 'id' | 'createdAt' | 'updatedAt'>): Promise<Customer> {
    const customersRef = collection(db, 'customers');
    const customerDoc = doc(customersRef);
    
    const customer: Customer = {
      id: customerDoc.id,
      ...customerData,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
    };

    await setDoc(customerDoc, customer);
    return customer;
  },

  async getCustomerByEmail(email: string): Promise<Customer | null> {
    const customersRef = collection(db, 'customers');
    const q = query(customersRef, where('email', '==', email));
    const snapshot = await getDocs(q);
    
    if (snapshot.empty) return null;
    return snapshot.docs[0].data() as Customer;
  },

  async getCustomerById(customerId: string): Promise<Customer | null> {
    try {
      const customerDoc = await getDoc(doc(db, 'customers', customerId));
      if (!customerDoc.exists()) return null;
      return customerDoc.data() as Customer;
    } catch (error) {
      console.error('Failed to get customer:', error);
      return null;
    }
  },

  // Child methods
  async createChild(childData: Omit<Child, 'id' | 'createdAt' | 'updatedAt'>): Promise<Child> {
    const childrenRef = collection(db, 'children');
    const childDoc = doc(childrenRef);
    
    const child: Child = {
      id: childDoc.id,
      ...childData,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
    };

    await setDoc(childDoc, child);
    return child;
  },

  async getChildrenByParentId(parentId: string): Promise<Child[]> {
    const childrenRef = collection(db, 'children');
    const q = query(childrenRef, where('parentId', '==', parentId));
    const snapshot = await getDocs(q);
    
    return snapshot.docs.map(doc => doc.data() as Child);
  },

  // Order methods
  async createOrder(orderData: Omit<Order, 'id' | 'createdAt' | 'updatedAt'>): Promise<Order> {
    console.log('📝 Creating order with data:', {
      childCount: orderData.orderDetails.childCount,
      pricing: orderData.orderDetails.pricing,
      addOns: orderData.orderDetails.addOns,
      timestamp: new Date().toISOString()
    });

    const ordersRef = collection(db, 'orders');
    const orderDoc = doc(ordersRef);
    
    const order: Order = {
      id: orderDoc.id,
      ...orderData,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
    };

    console.log('💾 Saving order to Firestore:', {
      orderId: order.id,
      amount: order.orderDetails.pricing.totalAmount,
      status: order.status
    });

    await setDoc(orderDoc, order);
    
    console.log('✅ Order created successfully:', {
      orderId: order.id,
      amount: order.orderDetails.pricing.totalAmount,
      timestamp: new Date().toISOString()
    });

    return order;
  },

  async updateOrderStatus(orderId: string, status: Order['status']): Promise<void> {
    const orderRef = doc(db, 'orders', orderId);
    await setDoc(orderRef, {
      status,
      updatedAt: serverTimestamp(),
    }, { merge: true });
  },

  async getOrdersByCustomerId(customerId: string): Promise<Order[]> {
    const ordersRef = collection(db, 'orders');
    const q = query(
      ordersRef, 
      where('customerId', '==', customerId),
      orderBy('createdAt', 'desc')
    );
    const snapshot = await getDocs(q);
    
    return snapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id
    })) as Order[];
  },

  async updateOrderPayment(orderId: string, paymentData: PaymentUpdate): Promise<void> {
    console.log('💳 Starting payment update:', {
      orderId,
      paymentData,  // Log entire payment data
      timestamp: new Date().toISOString()
    });

    try {
      const orderRef = doc(db, 'orders', orderId);
      
      // First check if order exists and is accessible
      const orderDoc = await getDoc(orderRef);
      if (!orderDoc.exists()) {
        console.error('❌ Order not found:', orderId);
        throw new Error('Order not found');
      }

      const updateData = {
        payment: {
          ...orderDoc.data().payment,  // Keep existing payment data
          ...paymentData,  // Merge new data
        },
        updatedAt: serverTimestamp(),
        status: paymentData.status === 'succeeded' ? 'processing' : 'pending'
      };

      console.log('📝 Update data:', {
        orderId,
        currentPayment: orderDoc.data().payment,
        newPayment: updateData.payment
      });

      await setDoc(orderRef, updateData, { merge: true });

      console.log('✅ Payment update successful:', {
        orderId,
        paymentIntentId: updateData.payment.stripePaymentIntentId
      });
    } catch (error) {
      console.error('❌ Payment update failed:', error);
      throw error;
    }
  },

  async getOrderByPaymentIntentId(paymentIntentId: string): Promise<Order | null> {
    console.log('🔍 Searching for order with payment intent:', paymentIntentId);
    
    const ordersRef = collection(db, 'orders');
    const q = query(
      ordersRef, 
      where('payment.stripePaymentIntentId', '==', paymentIntentId)
    );
    
    const snapshot = await getDocs(q);
    console.log('📄 Query result:', {
      empty: snapshot.empty,
      count: snapshot.docs.length,
      docs: snapshot.docs.map(doc => ({
        id: doc.id,
        paymentStatus: doc.data().payment?.status,
        orderStatus: doc.data().status,
        paymentIntent: doc.data().payment?.stripePaymentIntentId
      }))
    });
    
    if (snapshot.empty) return null;
    
    const doc = snapshot.docs[0];
    return {
      ...doc.data(),
      id: doc.id
    } as Order;
  },

  async getOrderById(orderId: string): Promise<Order | null> {
    try {
      const orderDoc = await getDoc(doc(db, 'orders', orderId));
      if (!orderDoc.exists()) return null;
      return {
        ...orderDoc.data(),
        id: orderDoc.id
      } as Order;
    } catch (error) {
      console.error('Failed to get order:', error);
      return null;
    }
  },

  // Keep this alias, remove the duplicate at the bottom
  getOrder(orderId: string): Promise<Order | null> {
    return this.getOrderById(orderId);
  },

  async getOrderByInvoiceId(invoiceId: string): Promise<Order | null> {
    const ordersRef = collection(db, 'orders');
    const q = query(
      ordersRef, 
      where('payment.invoiceId', '==', invoiceId),
      orderBy('createdAt', 'desc')
    );
    
    const snapshot = await getDocs(q);
    if (snapshot.empty) return null;
    
    return {
      ...snapshot.docs[0].data(),
      id: snapshot.docs[0].id
    } as Order;
  }
}; 