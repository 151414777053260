import { useState } from 'react';
import { motion } from 'framer-motion';
import { CreditCard, Building, ArrowRight } from 'lucide-react';
import { Text } from '../ui/Text';
import { Button } from '../ui/Button';
import { SecurityIndicators } from './SecurityIndicators';

interface Address {
  line1: string;
  city: string;
  postal_code: string;
  country: string;
}

interface PaymentMethodSelectionProps {
  onSelect: (method: 'card' | 'bank_transfer') => void;
  parentInfo: {
    email: string;
    firstName: string;
    lastName: string;
  };
  orderData?: {
    metadata: {
      orderId: string;
    };
    customer: {
      address: Address;
    };
    lineItems: Array<{
      quantity: number;
      price_data: {
        product: string;
      };
    }>;
    amount: number;
  } | null;
}

const PAYMENT_METHODS = [
  {
    id: 'card' as const,
    icon: CreditCard,
    title: 'Plačilo s kartico',
    description: 'Varno spletno plačilo s kreditno ali debetno kartico',
    badge: 'Takojšnje plačilo'
  },
  {
    id: 'bank_transfer',
    icon: Building,
    title: 'Plačilo po predračunu',
    description: 'Plačilo preko spletne banke ali UPN naloga',
    badge: 'Bančno nakazilo'
  }
] as const;

export function PaymentMethodSelection({ 
  onSelect, 
  parentInfo
}: PaymentMethodSelectionProps) {
  const [selectedMethod, setSelectedMethod] = useState<'card' | 'bank_transfer' | null>(null);

  const handleContinue = async () => {
    if (!selectedMethod) return;
    onSelect(selectedMethod);
  };

  return (
    <div className="space-y-6">
      <Text variant="large" className="font-semibold">
        Izberite način plačila
      </Text>

      <div className="space-y-4">
        {PAYMENT_METHODS.map((method) => (
          <motion.div
            key={method.id}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            className={`
              w-full p-4 rounded-xl border-2 transition-colors cursor-pointer
              ${selectedMethod === method.id 
                ? 'border-primary-500 bg-primary-50' 
                : 'border-neutral-200 hover:border-primary-500'
              }
            `}
            onClick={() => setSelectedMethod(method.id)}
          >
            <div className="flex items-center gap-4">
              <div className="w-12 h-12 rounded-full bg-primary-100 
                flex items-center justify-center text-primary-500"
              >
                <method.icon className="w-6 h-6" />
              </div>
              <div className="flex-1">
                <div className="flex items-center gap-2">
                  <Text variant="base" className="font-semibold">
                    {method.title}
                  </Text>
                  <span className="px-2 py-1 rounded-full bg-primary-100 text-primary-600 text-xs font-medium">
                    {method.badge}
                  </span>
                </div>
                <Text variant="small" className="text-neutral-500">
                  {method.description}
                </Text>
                {method.id === 'bank_transfer' && (
                  <Text variant="small" className="text-primary-600 mt-2">
                    Predračun bo poslan na {parentInfo.email}
                  </Text>
                )}
              </div>
              {selectedMethod === method.id && (
                <ArrowRight className="w-5 h-5 text-primary-500" />
              )}
            </div>
          </motion.div>
        ))}
      </div>

      <SecurityIndicators />

      <div className="fixed bottom-4 sm:bottom-6 md:bottom-8 left-0 right-0 px-3 xs:px-4 sm:px-6 max-w-2xl mx-auto">
        <Button
          variant="accent"
          size="lg"
          fullWidth
          onClick={handleContinue}
          disabled={!selectedMethod}
        >
          {selectedMethod === 'bank_transfer' 
            ? 'Nadaljuj na predračun' 
            : 'Nadaljuj na plačilo'
          }
        </Button>
      </div>
    </div>
  );
}