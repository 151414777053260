import React from 'react';
import { Button } from './ui/Button';
import { Text } from './ui/Text';

interface StartButtonProps {
  onClick: () => void;
}

export function StartButton({ onClick }: StartButtonProps) {
  return (
    <div className="fixed bottom-8 left-0 right-0 px-6 space-y-3 max-w-xl mx-auto">
      <Button 
        variant="accent"
        size="lg"
        fullWidth
        onClick={onClick}
      >
        Začni z Naročilom
      </Button>
      <Text variant="small" className="text-center text-white/90">
        Naročilo lahko zaključite v 3 minutah
      </Text>
    </div>
  );
}