import React from 'react';
import { Card } from './ui/Card';
import { Text } from './ui/Text';
import { tokens } from '../styles/tokens';

interface TimelineStep {
  number: string;
  title: string;
  description: string;
}

export function TimelineCard() {
  const steps: TimelineStep[] = [
    {
      number: "1",
      title: "Povejte nam o vaših otrocih",
      description: "Delite z nami njihova imena in kaj jih razveseli"
    },
    {
      number: "2",
      title: "Božiček pripravi sporočilo",
      description: "Personalizirano za vsakega otroka"
    },
    {
      number: "3",
      title: "Prejmite čarobni video",
      description: "Dostava na vaš email v nekaj urah"
    }
  ];

  return (
    <Card variant="white" className="p-6">
      <div className="space-y-8">
        {steps.map((step, index) => (
          <div key={step.number} className="relative">
            {/* Timeline connector */}
            {index < steps.length - 1 && (
              <div 
                className="absolute left-4 top-12 bottom-0 w-0.5 bg-primary-100"
                style={{ transform: 'translateX(-50%)' }}
              />
            )}
            
            <div className="flex items-start gap-6">
              {/* Step number */}
              <div 
                className="relative z-10 w-8 h-8 rounded-full bg-primary-500 flex items-center justify-center shrink-0"
                style={{ boxShadow: tokens.shadows.md }}
              >
                <Text variant="small" className="text-white font-semibold">
                  {step.number}
                </Text>
              </div>

              {/* Step content */}
              <div className="flex-1 pt-1">
                <Text variant="base" className="font-semibold text-neutral-900 mb-1">
                  {step.title}
                </Text>
                <Text variant="small" className="text-neutral-600">
                  {step.description}
                </Text>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
}