import React from 'react';
import { motion } from 'framer-motion';
import { ShieldCheck, Lock, CreditCard } from 'lucide-react';
import { Text } from '../ui/Text';

interface SecurityIndicator {
  icon: React.ElementType;
  title: string;
  description: string;
}

const indicators: SecurityIndicator[] = [
  {
    icon: ShieldCheck,
    title: 'Varno plačilo',
    description: 'Vaši podatki so zaščiteni z najnovejšo tehnologijo šifriranja'
  },
  {
    icon: Lock,
    title: 'SSL zaščita',
    description: 'Vsi podatki so šifrirani in varno shranjeni'
  },
  {
    icon: CreditCard,
    title: 'Zaupanja vredni partnerji',
    description: 'Sodelujemo s preverjenimi plačilnimi ponudniki'
  }
];

export function SecurityIndicators() {
  return (
    <div className="space-y-4">
      {indicators.map((indicator, index) => {
        const Icon = indicator.icon;
        
        return (
          <motion.div
            key={indicator.title}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            className="flex items-center gap-4 p-3 rounded-lg bg-white/50"
          >
            <div className="w-10 h-10 rounded-full bg-primary-50 
              flex items-center justify-center text-primary-500"
            >
              <Icon className="w-5 h-5" />
            </div>
            <div>
              <Text variant="small" className="font-semibold">
                {indicator.title}
              </Text>
              <Text variant="small" className="text-neutral-500">
                {indicator.description}
              </Text>
            </div>
          </motion.div>
        );
      })}
    </div>
  );
}