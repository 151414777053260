import React from 'react';
import { Card } from './ui/Card';
import { Text } from './ui/Text';
import { Heading } from './ui/Heading';
import { TimelineCard } from './TimelineCard';

export function ProcessSteps() {
  return (
    <div className="space-y-6">
      <Card variant="dark" className="p-6">
        <Heading level="h2" className="text-white mb-2">Kako deluje?</Heading>
        <Text variant="base" className="text-white/90">
          Ustvarite čarobno božično doživetje v treh korakih
        </Text>
      </Card>
      
      <TimelineCard />
      
      <Card variant="dark" className="p-5">
        <Text variant="small" className="text-white/90">
          <span className="text-white font-medium">Nasvet:</span> Naročite danes in izberite datum dostave po želji
        </Text>
      </Card>
    </div>
  );
}