import React from 'react';
import { components } from '../../styles/components';

interface HeadingProps {
  level: keyof typeof components.text.heading;
  className?: string;
  children: React.ReactNode;
}

export function Heading({
  level = 'h1',
  className = '',
  children,
}: HeadingProps) {
  const Tag = level;
  const styles = components.text.heading[level];
  
  return (
    <Tag className={`${styles} ${className}`}>
      {children}
    </Tag>
  );
}