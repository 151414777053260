import React from 'react';
import { components } from '../../styles/components';

interface CardProps {
  variant?: keyof typeof components.card.variants;
  className?: string;
  children: React.ReactNode;
}

export function Card({
  variant = 'white',
  className = '',
  children,
}: CardProps) {
  const baseStyles = components.card.base;
  const variantStyles = components.card.variants[variant];

  return (
    <div className={`${baseStyles} ${variantStyles} ${className}`}>
      {children}
    </div>
  );
}