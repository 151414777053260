import React from 'react';
import { motion } from 'framer-motion';
import { 
  GraduationCap, 
  Home, 
  Trophy, 
  BookOpen, 
  Music2, 
  Dog, 
  Palette, 
  Plus 
} from 'lucide-react';
import { Text } from '../ui/Text';

interface Achievement {
  id: string;
  icon: React.ElementType;
  label: string;
  color: string;
}

const achievements: Achievement[] = [
  { id: 'vrtec', icon: GraduationCap, label: 'Vrtec', color: 'text-blue-500' },
  { id: 'doma', icon: Home, label: 'Doma', color: 'text-green-500' },
  { id: 'sport', icon: Trophy, label: 'Šport', color: 'text-orange-500' },
  { id: 'sola', icon: BookOpen, label: 'Uspeh v šoli', color: 'text-purple-500' },
  { id: 'instrument', icon: Music2, label: 'Igranje inštrumenta', color: 'text-pink-500' },
  { id: 'zival', icon: Dog, label: 'Domača žival', color: 'text-yellow-500' },
  { id: 'hobiji', icon: Palette, label: 'Hobiji', color: 'text-indigo-500' },
  { id: 'ostalo', icon: Plus, label: 'Ostalo', color: 'text-gray-500' },
];

interface AchievementsGridProps {
  selected: string[];
  onChange: (selected: string[]) => void;
}

export function AchievementsGrid({ selected, onChange }: AchievementsGridProps) {
  const handleToggle = (id: string) => {
    if (selected.includes(id)) {
      onChange(selected.filter(item => item !== id));
    } else {
      onChange([...selected, id]);
    }
  };

  return (
    <div className="grid grid-cols-2 gap-3">
      {achievements.map((achievement) => {
        const Icon = achievement.icon;
        const isSelected = selected.includes(achievement.id);

        return (
          <motion.button
            key={achievement.id}
            onClick={() => handleToggle(achievement.id)}
            className={`
              relative p-4 rounded-xl border-2 transition-all
              ${isSelected 
                ? 'border-primary-500 bg-primary-50' 
                : 'border-neutral-200 hover:border-neutral-300'
              }
            `}
            whileTap={{ scale: 0.98 }}
          >
            <div className="flex flex-col items-center text-center gap-2">
              <div className={`w-10 h-10 rounded-full bg-white shadow-sm 
                flex items-center justify-center ${achievement.color}`}
              >
                <Icon className="w-5 h-5" />
              </div>
              <Text variant="small" className="font-medium">
                {achievement.label}
              </Text>
            </div>

            {/* Selection indicator */}
            {isSelected && (
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                className="absolute top-2 right-2 w-5 h-5 bg-primary-500 
                  rounded-full flex items-center justify-center"
              >
                <div className="w-2 h-2 bg-white rounded-full" />
              </motion.div>
            )}
          </motion.button>
        );
      })}
    </div>
  );
}